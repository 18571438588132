export const env = {
  ENDPOINT:process.env.REACT_APP_ENDPOINT_DEV,
    // process.env.NODE_ENV === "development"
    //   ? process.env.REACT_APP_ENDPOINT_DEV
    //   : process.env.REACT_APP_ENDPOINT,
  ONBOARING_ENDPOINT:process.env.REACT_APP_ONBOARING_ENDPOINT_DEV
    // process.env.NODE_ENV === "development"
    //   ? process.env.REACT_APP_ONBOARING_ENDPOINT_DEV
    //   : process.env.REACT_APP_ONBOARING_ENDPOINT,
};
