import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import PrivacyLogo from "../../../Assets/svg/PrivacyLogo";
import { DWOLLA_TOS, DWOLLA_PP, CARL_TOS_PP } from "../../../Constant";
import { Info } from "../../../Api/onBoarding";
import { AppContext } from "../../../Context/Context";
import "./style.css";
import { ApplicationStatus } from "../../../Constant/onboardingData";
import { Routes } from "../../../Constant/Routes/Routes";

const Termsandcondition = ({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) => {
  const { userId, applicationStatus } = React.useContext(AppContext);

  let localUserId: any = localStorage.getItem("userId");

  const [loading, setLoading] = React.useState(false);
  const [t_CCheckbox, setT_CCheckbox] = React.useState(false);
  const history = useHistory();
  const checkUserFlow = () => {
    if (applicationStatus === ApplicationStatus.PENDING) {
      history.replace(Routes.HOME_SCREEN);
    }
  };

  checkUserFlow();

  const handleSubmit = async () => {
    setLoading(true);
    const response = await Info.GetUserDetails();
    if (response.message.logout) {
      history.push("/", { step: 1 })
    }
    const {
      message: {
        data: {
          personalinfodata: { first_name, last_name, email },
        },
      },
    } = response;
    try {
      const data = {
        first_name,
        last_name,
        email,
      };
      await Info.submitUserDataToCARL(data, localUserId);
      handleToUpdate();
    } catch (error: any) {
      setLoading(false);
    }
  };

  const checkFlow = async () => {
    const { message = {} } = await Info.GetUserDetails();
    const {
      data: {
        personalinfodata = {},
        kycinfodata = {},
        addressinfodata = {},
        accredited_investor_data = {},
        business_entity_proof_data = {},
      },
    } = message;
    console.log("message new ??", message);
    const hasReloaded = localStorage.getItem("hasReloaded") || "false";
    // Reload the page only if kycinfodata is empty and we haven't reloaded yet
    if (
      !Object.keys(business_entity_proof_data).length &&
      hasReloaded !== "true"
    ) {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload(); // Reload the page
    }
  };
  React.useEffect(() => {
    checkFlow();
  }, []);

  return (
    <div
      className="w-100 d-flex align-items-center justify-content-center"
      style={{ height: "80vh" }}
    >
      <div className="application-container" style={{ width: "600px" }}>
        <div className="application-header">
          <div className="headerContainer">
            <h2 className="title">Your application is ready</h2>
          </div>
          <div className="logoContainer" style={{ height: "280px" }}>
            {/* <PrivacyLogo /> */}
            <iframe
              src={
                "https://res.cloudinary.com/dkjrqcdjy/image/upload/v1593767922/documents/ucvmdosv8armja4fro7k.pdf" +
                "#toolbar=0"
              } // Hides the toolbar with download option in some browsers
              title="Certificate"
              className="certificate-frame"
              style={{ border: "none" }}
            />
          </div>
          <div className="termsContainer">
            {" "}
            <div className="agreeTermsContainer">
              <div className="inputContainer">
                {/* <input
                name="options"
                type="radio"
                className="input"
                id="link"
                onChange={() => setT_CCheckbox(true)}
              /> */}
                <label className="newcontainer">
                  <input
                    name="options"
                    type="checkbox"
                    id="link"
                    onChange={() => setT_CCheckbox(!t_CCheckbox)}
                  />
                  <div className="checkmark"></div>
                </label>
                <label htmlFor="link" className="labelText">
                  I have read and agree to{" "}
                  <a
                    className="termLink"
                    href={DWOLLA_PP}
                    target="_blank"
                    rel="noreferrer"
                  >
                    CARL Terms of Service and Privacy Policy
                  </a>
                  .
                  {/* , as well as our partner{" "}
                <a
                  className="termLink"
                  href={DWOLLA_TOS}
                  target="_blank"
                  rel="noreferrer"
                >
                  Dwolla Terms of Service
                </a>{" "}
                and{" "}
                <a
                  className="termLink"
                  href={CARL_TOS_PP}
                  target="_blank"
                  rel="noreferrer"
                >
                  Dwolla Privacy Policy
                </a>
                . */}
                </label>
              </div>
            </div>
          </div>
          <Button
            label="Agree & Submit my application"
            className={t_CCheckbox ? "TermsButton" : "deactiveButton"}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            onClick={handleSubmit}
            disabled={!t_CCheckbox}
          />
        </div>
      </div>
    </div>
  );
};
export default Termsandcondition;
