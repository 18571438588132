import * as React from "react";
import "./style.css";

interface InputFieldProps {
  id?: string;
  name: string;
  placeholder: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  picker?: any;
  className?: any;
}

const InputField = ({
  name,
  placeholder,
  type,
  onChange,
  onBlur,
  value,
  id,
  picker,
  className,
}: InputFieldProps) => {
  return (
    <div className="form-group">
      <input
        id={id}
        type={type}
        name={name}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        autoComplete="off"
        ref={picker}
      />
    </div>
  );
};

export default InputField;
