import * as React from "react";
import { useHistory } from "react-router";
import SuccesLogo from "../../Assets/Images/check.png";
import "../../Components/SuccessScreen/SuccessScreen";
import { GetUserStatus } from "../../Api/onBoarding";
import { AppContext } from "../../Context/Context";
import { CARL_LINK } from "../../Constant/onboardingData";
interface SuccessScreenProps {
  title: string;
  heading: string;
  navigate?: string;
  button?: string;
  label?: string;
}

const RejectScreen = ({
  title,
  heading,
  navigate,
  button,
  label,
}: SuccessScreenProps) => {
  const history = useHistory();

  const {
    firstName,
    lastName,
    applicationStatus,
    setApplicationStatus,
    userId,
  } = React.useContext(AppContext);

  const checkFlow = React.useCallback(async () => {
    const data = await GetUserStatus.fetchMainData(userId);
    setApplicationStatus(data.message.application_status);
  }, [setApplicationStatus, userId]);

  React.useEffect(() => {
    checkFlow();
    setTimeout(() => {
      history.replace("/");
    }, 5000);
  }, [checkFlow]);

  const handler = () => {
    history.replace(`/${navigate}`);
  };

  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="logoContainer">
            {/* <img src={SuccesLogo} alt="logo" /> */}
          </div>
          <div className="successScreenContainer">
            <h2
              className="succesTitle"
              style={{ color: "white", fontSize: "32px" }}
            >
              Your application has not been successful at this time
            </h2>
            <h5 className="title" style={{ fontSize: "20px" }}>
              We regret to inform you that your application has not been
              accepted. If you believe this is an error, please feel free to
              reach out to us at <span className="footerSpan">
              <a
                className="termLink"
                href={`mailto:${CARL_LINK}`}
                target="_blank"
                rel="noreferrer"
              >
                {CARL_LINK}
              </a>
            </span>.
            </h5>
          </div>
          {/* {label === 'Done' && button ? null : (
            <div className="continueBtn">
              <Button
                label={label ? label : "Continue"}
                className="buttonContinue"
                onClick={handler}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default RejectScreen;
