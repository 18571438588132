import React from "react";
import { NON_US_SPLASH_TEXT } from "../../Constant/Content";

const NONUSSplash = ({ isUS }: any) => {
  return (
    <div>
      <p className="entitySplashText">{NON_US_SPLASH_TEXT}</p>
      <div className="listItems">
        <div style={{ height: "100%", margin: "0px 4px" }}>
          <div className="bullet" />
        </div>
        <p className="bulletText"> A copy of a goverment issue ID.</p>
      </div>
      <div className="listItems">
        <div style={{ height: "100%", margin: "0px 4px" }}>
          <div className="bullet" />
        </div>
        <p className="bulletText">A document showing you qualify as an accredited investor.</p>
      </div>
      {/* {!isUS ? ( */}
        <div className="listItems">
          <div style={{ height: "100%", margin: "0px 4px" }}>
            <div className="bullet" />
          </div>
          <p className="bulletText">
            An address verification document.
          </p>
        </div>
      {/* // ) : null} */}

      <p className="getStarted">Let’s get started.</p>
    </div>
  );
};

export default NONUSSplash;
