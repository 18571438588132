import React from "react";
import { isMobile } from "react-device-detect";

import { KYC, Address, Business } from "../../../Api/onBoarding";
import { BackArrow } from "../../../Assets/svg/BackArrow";
import Button from "../../../Components/Button/Button";
import MobileComponent from "../../../Components/Files/MobileView";
import PassportCapture from "../../../Components/Files/Passport";
import { AppContext } from "../../../Context/Context";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import ShowCapturedImages from "../../../Components/Files/ShowCapturedImage";
import { Info } from "../../../Api/onBoarding";
import { useHistory } from "react-router-dom";
type props = {
  title?: string;
  id?: number;
};

interface UploadDocumentsProps {
  handleToUpdate: () => void;
  documentTitle: props;
  setcurrentStep: any;
  isAddr: boolean;
  isbusiness?: boolean;
}

function UploadDocuments({
  handleToUpdate,
  documentTitle,
  setcurrentStep,
  isAddr,
  isbusiness,
}: UploadDocumentsProps) {
  const [frontImage, setFrontImage] = React.useState<File | null>(null);
  const [backImage, setBackImage] = React.useState<File | null>(null);
  const [flipSide, setFlipSide] = React.useState("front");
  const [loading, setLoading] = React.useState(false);
  let { userId, investorType } = React.useContext(AppContext);
  const [uploadError, setUploadError] = React.useState("");
  const history = useHistory();

  const { title: documentName, id: documentType } = documentTitle;
  const isInputEmpty =
    documentName === "Passport" || documentName === "Address Proof"
      ? //  || documentName === "Business Proof"
        frontImage === null
      : frontImage === null || backImage === null;

  const retakeImage = () => {
    setFrontImage(null);
    setBackImage(null);
    setFlipSide("front");
  };

  const goBack = () => {
    setcurrentStep(1);
  };

  // Updated image setter functions to clear uploadError
  const handleSetFrontImage = (image: File | null) => {
    setFrontImage(image);
    setUploadError(""); // Clear error when a front image is selected
  };

  const handleSetBackImage = (image: File | null) => {
    setBackImage(image);
    setUploadError(""); // Clear error when a back image is selected
  };

  const submitForm = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      userId,
      documentType,
      frontImage,
      backImage,
    };

    if (isAddr) {
      try {
        const res = await Address.submitDoc(data);
        if (res.data.logout) {
          history.push("/", { step: 1 });
        } else if (res.data.success) {
          setUploadError("");
          handleToUpdate();
          localStorage.setItem("img", res.data.address_proof_url);
        } else {
          setLoading(true);
        }
      } catch (error: any) {
        setUploadError("File size exceeded. Maximum allowed size is 20 MB.");
        setLoading(false);
      }
    }
    // else if (isbusiness) {
    //   handleToUpdate();
    //   // console.log("in busniess block");
    //   try {
    //     const res = await Business.businessDoc(data);
    //     if (res.data.success) {
    //       handleToUpdate();
    //       localStorage.setItem("img",res.data.address_proof_url)
    //     } else {
    //       setLoading(true);
    //     }
    //   } catch (error) {
    //     setLoading(true);
    //   }
    // }
    else {
      try {
        const res = await KYC.submit(data);
        if (res.data.logout) {
          history.push("/", { step: 1 });
        } else if (res.data.success) {
          setUploadError("");
          handleToUpdate();
        } else {
          setLoading(true);
        }
      } catch (error) {
        setUploadError("File size exceeded. Maximum allowed size is 20 MB.");
        setLoading(false);
      }
    }
  };

  const checkFlow = async () => {
    const hasReloaded = localStorage.getItem("hasReloaded") || "false";
    // Reload the page only if kycinfodata is empty and we haven't reloaded yet
    if (localStorage.getItem("img") && hasReloaded !== "true") {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload(); // Reload the page
    }
  };
  React.useEffect(() => {
    checkFlow();
  }, []);
  return (
    <div className="container">
      <div className="documentContainer">
        <div className="topContainer">
          <div className="headerWithBack">
            {documentName !== "Address Proof" &&
              documentName !== "Business Proof" && (
                <BackArrow
                  onClick={!loading ? goBack : undefined}
                  style={{
                    marginTop: "0.25rem",
                    cursor: loading ? "not-allowed" : "pointer",
                    opacity: loading ? 0.5 : 1,
                  }}
                />
              )}
            <h1 className="title">{`Upload your ${
              documentName === "Address Proof"
                ? investorType === "1" || investorType === "2"
                  ? "Entity Address Verification Document"
                  : "Address Verification Document"
                : documentName
              // === "Business Prrof"
              // ? "Upload a document that shows you are legally authorized to make decisions for the company."
              // : documentName
            }`}</h1>
          </div>

          <div className="text-center">
            {documentName === "Address Proof" && (
              <p style={{ color: "#D819FF" }}>
                Example: utility bill or bank statement.
              </p>
            )}
          </div>
          {documentName === "Passport" ||
          documentName === "Address Proof" ||
          documentName === "Business Proof" ? (
            <PassportCapture
              frontImage={frontImage}
              setFrontImage={handleSetFrontImage}
              retakeImage={retakeImage}
              disabled={loading}
            />
          ) : (
            <MobileComponent
              frontImage={frontImage}
              backImage={backImage}
              flipSide={flipSide}
              setFrontImage={handleSetFrontImage}
              retakeImage={retakeImage}
              setBackImage={handleSetBackImage}
              disabled={loading}
            />
          )}
          {uploadError !== "" && <p style={{ color: "red" }}>{uploadError}</p>}
        </div>
        <PrivacyContainer />
        <div className={!isMobile ? "button-cont-text" : ""}>
          <Button
            label="Continue"
            className={
              isInputEmpty || uploadError !== ""
                ? "disableButton"
                : "buttonContinue"
            }
            disabled={isInputEmpty || uploadError !== ""}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            onClick={submitForm}
          />
        </div>
      </div>
    </div>
  );
}

export default UploadDocuments;
