import React from "react";

const BusinessSVG = () => {
  return (
    <svg
      width="162"
      height="162"
      viewBox="0 0 162 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="81" cy="81" r="78" stroke="#D819FF" strokeWidth="6" />
      <path
        d="M103 44L118 59"
        stroke="#D819FF"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M99 64H117"
        stroke="#D819FF"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M98 64V46"
        stroke="#D819FF"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M51 120L51 103"
        stroke="#D819FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M71 117V102"
        stroke="#D819FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M51 120L61 114"
        stroke="#D819FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M71 120L61 114"
        stroke="#D819FF"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M59.3733 67.6651L61 65.4144L62.6267 67.6651C63.5295 68.9143 65.2319 69.2761 66.5646 68.5022L68.9662 67.1076L69.5368 69.8255C69.8535 71.3338 71.2615 72.3568 72.7938 72.1918L75.555 71.8946L74.9708 74.6096C74.6466 76.1163 75.5168 77.6236 76.9837 78.0961L79.627 78.9477L77.9891 81.1903C77.0801 82.4349 77.262 84.1658 78.4099 85.1942L80.4783 87.0473L78.0699 88.4298C76.7332 89.197 76.1954 90.8523 76.8258 92.2587L77.9617 94.7928L75.1991 95.0762C73.6659 95.2335 72.5014 96.5269 72.5052 98.068L72.5121 100.845L69.8731 99.9804C68.4086 99.5004 66.8186 100.208 66.1953 101.618L65.0721 104.158L63.013 102.294C61.8702 101.26 60.1298 101.26 58.987 102.294L56.9279 104.158L55.8047 101.618C55.1814 100.208 53.5914 99.5004 52.1269 99.9804L49.4878 100.845L49.4948 98.068C49.4986 96.5268 48.3341 95.2335 46.8009 95.0762L44.0383 94.7928L45.1742 92.2587C45.8046 90.8523 45.2668 89.197 43.9301 88.4298L41.5217 87.0473L43.5901 85.1942C44.738 84.1658 44.9199 82.4349 44.0109 81.1903L42.373 78.9477L45.0163 78.0961C46.4832 77.6236 47.3534 76.1163 47.0292 74.6096L46.445 71.8946L49.2062 72.1918C50.7385 72.3568 52.1465 71.3338 52.4632 69.8255L53.0338 67.1076L55.4354 68.5022C56.7681 69.2761 58.4705 68.9143 59.3733 67.6651Z"
        stroke="#D819FF"
        strokeWidth="4"
      />
      <mask id="path-10-inside-1_1042_715" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102 42.9707L103.971 41H67C63.6863 41 61 43.6863 61 47V68H66V47C66 46.4477 66.4477 46 67 46H105.029L102 42.9707ZM72 116V121H115C118.314 121 121 118.314 121 115V57.9118L118.971 59.9413L116 56.9707V115C116 115.552 115.552 116 115 116H72Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102 42.9707L103.971 41H67C63.6863 41 61 43.6863 61 47V68H66V47C66 46.4477 66.4477 46 67 46H105.029L102 42.9707ZM72 116V121H115C118.314 121 121 118.314 121 115V57.9118L118.971 59.9413L116 56.9707V115C116 115.552 115.552 116 115 116H72Z"
        fill="#D819FF"
      />
      <path
        d="M103.971 41L104.678 41.7071L106.385 40H103.971V41ZM102 42.9707L101.293 42.2636L100.586 42.9707L101.293 43.6778L102 42.9707ZM61 68H60V69H61V68ZM66 68V69H67V68H66ZM105.029 46V47H107.444L105.736 45.2929L105.029 46ZM72 116V115H71V116H72ZM72 121H71V122H72V121ZM121 57.9118H122V55.4976L120.293 57.2047L121 57.9118ZM118.971 59.9413L118.263 60.6484L118.971 61.3555L119.678 60.6484L118.971 59.9413ZM116 56.9707L116.707 56.2636L115 54.5565V56.9707H116ZM103.264 40.2929L101.293 42.2636L102.707 43.6778L104.678 41.7071L103.264 40.2929ZM67 42H103.971V40H67V42ZM62 47C62 44.2386 64.2386 42 67 42V40C63.134 40 60 43.134 60 47H62ZM62 68V47H60V68H62ZM61 69H66V67H61V69ZM65 47V68H67V47H65ZM67 45C65.8954 45 65 45.8954 65 47H67V47V45ZM105.029 45H67V47H105.029V45ZM101.293 43.6778L104.322 46.7071L105.736 45.2929L102.707 42.2636L101.293 43.6778ZM71 116V121H73V116H71ZM115 120H72V122H115V120ZM120 115C120 117.761 117.761 120 115 120V122C118.866 122 122 118.866 122 115H120ZM120 57.9118V115H122V57.9118H120ZM119.678 60.6484L121.707 58.6189L120.293 57.2047L118.263 59.2342L119.678 60.6484ZM115.293 57.6778L118.263 60.6484L119.678 59.2342L116.707 56.2636L115.293 57.6778ZM117 115V56.9707H115V115H117ZM115 117C116.105 117 117 116.105 117 115H115V117ZM72 117H115V115H72V117Z"
        fill="#D819FF"
        mask="url(#path-10-inside-1_1042_715)"
      />
    </svg>
  );
};

export default BusinessSVG;
