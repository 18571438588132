import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Smlllogo from "../../Assets/Images/logo.png";
import "./Header.css";
import Form from 'react-bootstrap/Form';
import * as FiIcons from 'react-icons/fi'
import * as IoIcons from 'react-icons/io'
import * as RiIcons from 'react-icons/ri'
import BarAnimation from "./Animation/BarAnimation";


const Header = (props) => {
  return (
    <>
      <div className="headbg p-2 d-flex justify-content-between align-items-center">
        <div className="topSection p-2 d-flex align-items-center">
          <div className="d-flex"><img src="https://investwithcarl.com/typo3conf/ext/carl-template/Resources/Public/images/logo.svg" className="smallLogo" alt="small Logo" /> </div>
          {/* <div type='button' onClick={props.onClick}><RiIcons.RiBarChartHorizontalFill className="myIcon" /></div> */}
          {/* <BarAnimation className="myIcon" onClick={props.onClick}/> */}
        </div>
        {/* <div className="w-25">
          <Form.Control type="text" placeholder="Search" className=" mr-sm-2" />
        </div> */}
        <div className="px-2 d-flex align-items-center btn_cursor">
          <div className="d-flex align-items-center" onClick={props.handleProfile}>
            <div className="profileIcon"><img src={props?.userContent?.message?.data?.kycinfodata?.frontImageUrl} className="w-100 h-100" alt="small Logo" /></div>
            <div className="float-end px-2">
              <div className="userName">{props?.userContent?.message?.data?.personalinfodata?.first_name} {props?.userContent?.message?.data?.personalinfodata?.last_name}</div>
            </div>
          </div>
          {/* <IoIcons.IoIosNotificationsOutline className="myIcon" /> */}
          <Link to="/" onClick={(e) => localStorage.clear(e)}> <FiIcons.FiPower className="myIcon" /></Link>
        </div>
      </div>
    </>
  );
};

export default Header;
