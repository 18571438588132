import React from 'react';

const SuccessSVG = () => {
  return (
    <svg width="162" height="162" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="81" cy="81" r="78" stroke="#D819FF" stroke-width="6"/>
<path d="M40 80L71 106" stroke="#D819FF" stroke-width="6" stroke-linecap="round"/>
<path d="M71 106L121 49" stroke="#D819FF" stroke-width="6" stroke-linecap="round"/>
</svg>
  );
}

export default SuccessSVG;
