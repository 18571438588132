import React from "react";

const AccreditedSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="162"
      height="162"
      viewBox="0 0 162 162"
    >
      <g transform="translate(-461 -13067)">
        <circle
          cx="78"
          cy="78"
          r="78"
          transform="translate(464 13070)"
          fill="#06205c"
        />
        <path
          d="M78-3A81,81,0,1,1-3,78,81.092,81.092,0,0,1,78-3Zm0,156A75,75,0,1,0,3,78,75.085,75.085,0,0,0,78,153Z"
          transform="translate(464 13070)"
          fill="#d819ff"
        />
        <g transform="translate(492.681 13045.384)">
          <path
            d="M2,67.5v51.9H96.638V67.5Zm3.055,3.055H17.183A13.762,13.762,0,0,1,5.055,82.683Zm0,45.791V104.219a13.762,13.762,0,0,1,12.128,12.128Zm88.527,0H81.455a13.762,13.762,0,0,1,12.128-12.128Zm0-15.183a16.821,16.821,0,0,0-15.2,15.2H20.257a16.821,16.821,0,0,0-15.2-15.2V85.757a16.821,16.821,0,0,0,15.2-15.2H78.381a16.821,16.821,0,0,0,15.2,15.2Zm0-18.48A13.762,13.762,0,0,1,81.455,70.555H93.582Z"
            fill="#d819ff"
          />
          <path
            d="M2,362.5H96.638v3.055H2Z"
            transform="translate(0 -240.043)"
            fill="#d819ff"
          />
          <path
            d="M2,395.3H96.638v3.055H2Z"
            transform="translate(0 -266.733)"
            fill="#d819ff"
          />
          <path
            d="M2,428.1H96.638v3.055H2Z"
            transform="translate(0 -293.422)"
            fill="#d819ff"
          />
          <path
            d="M229.31,145.883a3.057,3.057,0,0,1,3.055,3.055h3.055a6.11,6.11,0,0,0-4.583-5.887V141.3h-3.055v1.751a6.11,6.11,0,0,0-4.583,5.887,5.967,5.967,0,0,0,6.11,6.11,3.055,3.055,0,1,1-3.055,3.055H223.2a6.11,6.11,0,0,0,4.583,5.887v1.751h3.055v-1.77a6.11,6.11,0,0,0,4.583-5.887c0-5.906-6.11-6.11-6.11-6.11a2.816,2.816,0,0,1-3.055-3.055A3.053,3.053,0,0,1,229.31,145.883Z"
            transform="translate(-179.992 -60.051)"
            fill="#d819ff"
          />
          <path
            d="M192.415,108.5a18.313,18.313,0,1,0,18.315,18.313A18.329,18.329,0,0,0,192.415,108.5Zm0,33.589c-8.073,0-14.648-6.856-14.648-15.258,0-8.421,6.575-15.258,14.648-15.258s14.648,6.856,14.648,15.258S200.488,142.089,192.415,142.089Z"
            transform="translate(-143.096 -33.362)"
            fill="#d819ff"
          />
        </g>
      </g>
    </svg>
  );
};

export default AccreditedSVG;
