import React, { ChangeEvent, FocusEvent, useEffect } from "react";
import InputMask from "react-input-mask";
import Button from "../../../Components/Button/Button";
import InputField from "../../../Components/Input/InputField/InputField";
import { Info } from "../../../Api/onBoarding";
import { AppContext } from "../../../Context/Context";
import "./perfsonalInfoStyle.css";
import Logo from "../../../Assets/Images/logo.png";
import dayjs from "dayjs";
import moment, { Moment } from "moment";
import { DatePicker, DatePickerProps } from "antd";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import { useHistory } from "react-router-dom";

export default function PersonalInfo({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) {
  const thirteenYearsAgo: Moment = moment().subtract(13, "years");
  const { firstName, setFirstName } = React.useContext(AppContext);
  const { lastName, setLastName } = React.useContext(AppContext);
  // const { email,loginEmail, setEmailAddress } = React.useContext(AppContext);
  const { loginEmail } = React.useContext(AppContext);
  const [dateOfBirth, setDateOfBirth] = React.useState<Moment | null>(
    thirteenYearsAgo
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | boolean>(false);
  const [SSN, setSSN] = React.useState("");
  const [firstNameValue, setFirstNameValue] = React.useState<string>("");
  const [firstNameError, setFirstNameError] = React.useState<string>("");
  const [lastNameValue, setLastNameValue] = React.useState<string>("");
  const [lastNameError, setLastNameError] = React.useState<string>("");
  const [ssnError, setSsnError] = React.useState<boolean>(true); // Default to true for error state
  const history = useHistory();

  let localStoredEmail: any = localStorage.getItem("email");
  const email = loginEmail ? loginEmail : JSON.parse(localStoredEmail);

  const isInputEmpty =
    firstNameValue &&
    lastNameValue &&
    email &&
    dateOfBirth &&
    firstNameError === "" &&
    lastNameError === "" &&
    !ssnError && // SSN validation passes
    SSN.replace(/[^0-9]/g, "").length === 9
      ? true
      : false;

  const ssn = SSN.replace(/[^+0-9]/g, "");
  const newDate = dayjs();
  const thirteenYears = newDate.year() - 13;
  const birthDate1 = newDate.format(JSON.stringify(dateOfBirth));
  const minDate1 = newDate.year(thirteenYears).format();

  const handleFirstNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const firstName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/; // Allow alphabetic characters and spaces

    // First check for character validation
    if (!characterRegex.test(firstName)) {
      setFirstNameError(
        "Input must contain only alphabetic characters and spaces."
      );
    }
    // Then check for length validation
    else if (firstName.length > 30) {
      setFirstNameError("Must be 30 characters or less.");
    } else {
      setFirstNameValue(firstName);
      setFirstName(firstName);
      setFirstNameError(""); // No error
    }
  };

  const handleFirstNameBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
    if (firstNameValue === "") {
      setFirstNameError("First name is required.");
    } else if (
      characterRegex.test(firstNameValue) &&
      firstNameValue.length <= 30
    ) {
      setFirstNameError("");
    }
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const lastName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/; // Allow alphabetic characters and spaces

    // First check for character validation
    if (!characterRegex.test(lastName)) {
      setLastNameError(
        "Input must contain only alphabetic characters and spaces."
      );
    }
    // Then check for length validation
    else if (lastName.length > 30) {
      setLastNameError("Must be 30 characters or less.");
    } else {
      setLastNameValue(lastName);
      setLastName(lastName);
      setLastNameError(""); // No error
    }
  };

  const handleLastNameBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
    if (lastNameValue === "") {
      setLastNameError("Last name is required.");
    } else if (
      characterRegex.test(lastNameValue) &&
      lastNameValue.length <= 30
    ) {
      setLastNameError("");
    }
  };

  const handleSSNChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const ssn = event.target.value;
    setSSN(ssn);
    setSsnError(ssn.replace(/[^0-9]/g, "").length !== 9); // SSN must be 9 digits
  };

  const handleSubmit = async () => {
    setError(false);
    const userDetails: any = {
      firstName: firstName,
      lastName: lastName,
      email: email,
    };
    localStorage.setItem("UserDetails", JSON.stringify(userDetails));
    const formattedDateOfBirth = moment(dateOfBirth).format("MM-DD-YYYY");
    setLoading(true);
    const userData = {
      first_name: firstName,
      last_name: lastName,
      email,
      dob: formattedDateOfBirth,
      ssn,
      device_info: "Web",
    };
    if (dayjs(birthDate1) > dayjs(minDate1)) {
      setLoading(false);
      setError("You must be 13 years old");
    } else {
      try {
        const response = await Info.update(userData);
        // Check if response has a status and handle based on success
        if (response.message.logout) {
          history.push("/", { step: 1 })
        } else if (response.status) {
          if (response.message.success === false) {
            // setError(response.message.message); // Set error from message
            setLoading(false);
          } else {
            handleToUpdate(); // Proceed if successful
          }
        } else {
          // // Handle case where response status is false
          // const parsedError = JSON.parse(response.message);
          // const errorMessage = Object.values(parsedError).join(); // Concatenate error messages

          // // Extract the string before the comma
          // const partBeforeComma = errorMessage.split(",")[0].trim(); // Get the part before comma

          // // Replace keywords accordingly
          // const formattedErrorMessage = partBeforeComma.replace(/this/g, "firstname or lastname"); // Change to firstName or lastName as needed
          // console.log("formattedErrorMessage ?", formattedErrorMessage);

          // setError(formattedErrorMessage); // Set the formatted error message
          setLoading(false);
        }
      } catch (error) {
        console.error("An error occurred:", error); // Log the error
        setLoading(false); // Ensure loading is stopped in case of error
        setError("An unexpected error occurred. Please try again."); // Generic error message
      }
    }
  };

  useEffect(() => {
    if (dateOfBirth !== null) {
      setError("");
    }
  }, [dateOfBirth]);

  const onChange: DatePickerProps["onChange"] = (_, dateString: string) => {
    // Check if dateString is a valid date
    if (dateString === "") {
      // Handle the case when the date is cleared using the cross icon
      setDateOfBirth(null);
      setError("Please select valid birthdate, it must be 13 years old.");
    } else if (moment(dateString, "MM-DD-YYYY", true).isValid()) {
      setDateOfBirth(moment(dateString, "MM-DD-YYYY"));
      setError("");
    } else {
      setError("Please select valid birthdate, it must be 13 years old.");
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      // Reset to default date if no date is selected when opening
      setDateOfBirth(thirteenYearsAgo);
    }
  };

  const disabledDate = (current: Moment | null): boolean => {
    // Disable future dates and dates less than 13 years ago
    return !!(current && (current > moment() || current > thirteenYearsAgo));
  };

  const datePicker = () => {
    return (
      <DatePicker
        value={dateOfBirth} // Control the displayed value
        placeholder="Date of Birth"
        onChange={onChange}
        onOpenChange={onOpenChange} // Reset to default when reopened
        format="MM-DD-YYYY"
        inputReadOnly // Prevent manual input
        allowClear
        disabledDate={disabledDate}
      />
    );
  };

  return (
    <div className="container">
      <div className="header">
        <div className="mainTitleContainer">
          <img src={Logo} width="60px" alt="Logo" />{" "}
          <span className="mainTitle">CARL</span>
        </div>
        <div className="mainheadingContainer">
          <h4 className="title">Investor Details</h4>
        </div>
        <div style={{ marginTop: 20 }}>
          <InputField
            type="text"
            placeholder="First Name"
            name="firstName"
            value={firstNameValue}
            onChange={handleFirstNameChange}
            onBlur={handleFirstNameBlur}
            className="inputField"
          />
          {firstNameError !== "" && (
            <p style={{ color: "red" }}>{firstNameError}</p>
          )}
          <InputField
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={lastNameValue}
            onChange={handleLastNameChange}
            onBlur={handleLastNameBlur}
            className="inputField"
          />
          {lastNameError !== "" && (
            <p style={{ color: "red" }}>{lastNameError}</p>
          )}
          {/* <InputField
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={(e) => setEmailAddress(e.target.value)}
            className="inputField"
          /> */}
          {datePicker()}
          <InputMask
            mask="999-99-9999"
            className="inputField"
            maskChar={null}
            onChange={handleSSNChange}
            placeholder="Social Security Number"
          />
          {SSN.length > 0 && ssnError && (
            <p style={{ color: "red" }}>SSN must be 9 digits.</p>
          )}
        </div>

        <div>
          <h6 style={{ color: "red", marginTop: 20, textAlign: "center" }}>
            {error ? `* ${error}` : null}
          </h6>
        </div>
        <PrivacyContainer />
        <div className="continueBtn">
          <Button
            label="Continue"
            className={isInputEmpty ? "buttonContinue" : "deactiveButton"}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            disabled={!isInputEmpty || loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
