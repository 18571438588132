import React from "react";
import "./radiobutton.css";
import { AppContext } from "../../Context/Context";

interface RadioButtonProps {
  description: string;
  id: number;
}

const RadioButton = ({ description, id }: RadioButtonProps) => {
  const { investorOptionSelect, setinvestorOptionSelect } =
    React.useContext(AppContext);

  const checkHandler = (value: string) => {
    // Always ensure only one checkbox is selected by replacing the previous value
    setinvestorOptionSelect(value);
  };

  return (
    <div className="mainContainer" style={{padding:"0px"}}>
      <div className="inputContainer" style={{padding:"0px"}}>
        <label className="newcontainer">
          <input
            name="options"
            type="checkbox"
            id={id.toString()}
            value={id}
            checked={investorOptionSelect === id.toString()}
            onChange={() => checkHandler(id.toString())}
          />
          <div className="checkmark"></div>
        </label>
        <label className="labelText" htmlFor={id.toString()} style={{padding:"10px 0px", width:"100%"}}>
          {description}
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
