import React from "react";

const IdcardSvg = () => {
  return (
    <svg
      width="162"
      height="162"
      viewBox="0 0 162 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="81" cy="81" r="78" stroke="#D819FF" strokeWidth="6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79 52H61C58.2386 52 56 54.2386 56 57V64H59V57C59 55.8954 59.8954 55 61 55H79V52ZM85 55H103C104.105 55 105 55.8954 105 57V116C105 117.105 104.105 118 103 118H61C59.8954 118 59 117.105 59 116V69H56V116C56 118.761 58.2386 121 61 121H103C105.761 121 108 118.761 108 116V57C108 54.2386 105.761 52 103 52H85V55Z"
        fill="#D819FF"
      />
      <path
        d="M64 110L64 114"
        stroke="#D819FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M76 110L76 114"
        stroke="#D819FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M88 110L88 114"
        stroke="#D819FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M100 110L100 114"
        stroke="#D819FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="57"
        y1="104.5"
        x2="106"
        y2="104.5"
        stroke="#D819FF"
        strokeWidth="3"
      />
      <line
        x1="58"
        y1="85.5"
        x2="107"
        y2="85.5"
        stroke="#D819FF"
        strokeWidth="3"
      />
      <path
        d="M64 92.5L78 92.5"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M64 98.5L90 98.5"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="82.5" cy="69.5" r="5.5" stroke="#D819FF" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.6264 87C90.8693 86.2096 91 85.3701 91 84.5C91 79.8056 87.1944 76 82.5 76C77.8056 76 74 79.8056 74 84.5C74 85.3701 74.1307 86.2096 74.3736 87H72.2994C72.1038 86.1988 72 85.3615 72 84.5C72 78.701 76.701 74 82.5 74C88.299 74 93 78.701 93 84.5C93 85.3615 92.8962 86.1988 92.7006 87H90.6264Z"
        fill="#D819FF"
      />
      <mask id="path-13-inside-1_1042_669" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79 46C77.8954 46 77 46.8954 77 48V57H77.0747C77.1259 57.2037 77.2451 57.3969 77.4432 57.547L81.396 60.5423C81.7532 60.813 82.2468 60.813 82.604 60.5423L86.5568 57.547C86.7549 57.3969 86.8741 57.2037 86.9253 57H87V48C87 46.8954 86.1046 46 85 46H79Z"
        />
      </mask>
      <path
        d="M77 57H75V59H77V57ZM77.0747 57L79.0145 56.5128L78.6345 55H77.0747V57ZM77.4432 57.547L76.2353 59.141L76.2353 59.1411L77.4432 57.547ZM81.396 60.5423L80.1881 62.1364L80.1881 62.1364L81.396 60.5423ZM82.604 60.5423L83.8119 62.1364L83.8119 62.1364L82.604 60.5423ZM86.5568 57.547L87.7647 59.1411L87.7647 59.141L86.5568 57.547ZM86.9253 57V55H85.3655L84.9855 56.5128L86.9253 57ZM87 57V59H89V57H87ZM79 48V44C76.7909 44 75 45.7909 75 48H79ZM79 57V48H75V57H79ZM77.0747 55H77V59H77.0747V55ZM75.135 57.4872C75.296 58.1281 75.6709 58.7133 76.2353 59.141L78.6512 55.953C78.8193 56.0804 78.9558 56.2794 79.0145 56.5128L75.135 57.4872ZM76.2353 59.1411L80.1881 62.1364L82.604 58.9483L78.6512 55.953L76.2353 59.1411ZM80.1881 62.1364C81.2596 62.9483 82.7404 62.9483 83.8119 62.1364L81.396 58.9483C81.7532 58.6777 82.2468 58.6777 82.604 58.9483L80.1881 62.1364ZM83.8119 62.1364L87.7647 59.1411L85.3488 55.953L81.396 58.9483L83.8119 62.1364ZM87.7647 59.141C88.3291 58.7133 88.704 58.1281 88.865 57.4872L84.9855 56.5128C85.0442 56.2794 85.1807 56.0804 85.3488 55.953L87.7647 59.141ZM87 55H86.9253V59H87V55ZM85 48V57H89V48H85ZM85 48H89C89 45.7909 87.2091 44 85 44V48ZM79 48H85V44H79V48Z"
        fill="#D819FF"
        mask="url(#path-13-inside-1_1042_669)"
      />
      <line
        x1="71.4142"
        y1="40"
        x2="78"
        y2="46.5858"
        stroke="#D819FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="10.3137"
        y2="-1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 94 40)"
        stroke="#D819FF"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IdcardSvg;
