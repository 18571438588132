import React from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import Identification from "../../Onboarding/IdentificationScreens/Identification";
import Documents from "../../Onboarding/IdentificationScreens/Documents";
import UploadDocuments from "../../Onboarding/IdentificationScreens/UploadDocuments";
import MessageScreen from "../../../Components/SuccessScreen/SuccessScreen";
import { Routes } from "../../../Constant/Routes/Routes";

type props = {
  title?: string;
  id?: number;
};

const arraySteps = [
  { title: "Identification" },
  { title: "Documents" },
  { title: "Upload Documents" },
  { title: "Success" },
];

const IdentificationStepper = () => {
  const [currentStep, setcurrentStep] = React.useState(0);
  const [title, settitle] = React.useState<props>({});

  const handleToUpdate = (Title?: props) => {
    setcurrentStep(currentStep + 1);
    settitle(Title || {});
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <Identification handleToUpdate={handleToUpdate} />;
      case 1:
        return <Documents handleToUpdate={handleToUpdate} />;
      case 2:
        return (
          <UploadDocuments
            handleToUpdate={handleToUpdate}
            documentTitle={title}
            setcurrentStep={setcurrentStep}
            isAddr={false}
          />
        );
      case 3:
        return (
          <MessageScreen
            title="Success"
            heading="We have received your document."
            navigate={Routes.ACCREDITED_DOC}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
        {renderComponent()}
      </MainStepper>
    </div>
  );
};

export default IdentificationStepper;
