import { parsePhoneNumberFromString } from "libphonenumber-js";

// This function is for showing static phone numbers in views
export const formatPhoneNumber = (phoneNumber: string, viewOnly = true) => {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
  if (parsedPhoneNumber) {
    return !viewOnly
      ? parsedPhoneNumber.formatInternational(phoneNumber).replace(" ", " | ")
      : parsedPhoneNumber.formatInternational().replace(/[^+0-9]/g, "-");
  }
  return phoneNumber;
};

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export function isEmail(value: string) {
  return value.includes("@");
}

export function isNotEmpty(value: string) {
  return value.trim() !== '';
}

export function hasMinLength(value: string, minLength: number) {
  return value.length >= minLength;
}

export function isEqualsToOtherValue(value: any, otherValue: any) {
  return value === otherValue;
}

