import * as React from "react";

interface ShowCapturedImagesProps {
  frontImageURL: string;
  backImageURL?: string;
  retakeImage: () => void;
}

const ShowCapturedImages: React.FC<ShowCapturedImagesProps> = ({
  frontImageURL,
  backImageURL,
  retakeImage,
}) => {
  return (
    <>
      <img src={frontImageURL} alt="frontImage" className="documentImage" />
      {backImageURL ? (
        <img src={backImageURL} alt="frontImage" className="documentImage" />
      ) : null}
      <button onClick={retakeImage} className="backsideButton">
        Try Again
      </button>
    </>
  );
};

export default ShowCapturedImages;
