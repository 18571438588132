import React from "react";

const CardSVG = () => {
  return (
    <svg
      width="162"
      height="162"
      viewBox="0 0 162 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="81" cy="81" r="78" stroke="#D819FF" strokeWidth="6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81 60H77V57H81C85.9706 57 90 61.0294 90 66V69H87V66C87 62.6863 84.3137 60 81 60ZM34 69V66C34 62.6863 36.6863 60 40 60H43V57H40C35.0294 57 31 61.0294 31 66V69H34ZM31 105V107C31 111.971 35.0294 116 40 116H43V113H40C36.6863 113 34 110.314 34 107V105H31ZM87 105H90V107C90 111.971 85.9706 116 81 116H77V113H81C84.3137 113 87 110.314 87 107V105Z"
        fill="#D819FF"
      />
      <path
        d="M94 61.5L92.5 61.5L92.5 64.5L94 64.5L94 61.5ZM106 64.5C106.828 64.5 107.5 63.8284 107.5 63C107.5 62.1716 106.828 61.5 106 61.5L106 64.5ZM94 64.5L106 64.5L106 61.5L94 61.5L94 64.5Z"
        fill="#D819FF"
      />
      <path
        d="M94 69.5L92.5 69.5L92.5 72.5L94 72.5L94 69.5ZM122 72.5C122.828 72.5 123.5 71.8284 123.5 71C123.5 70.1716 122.828 69.5 122 69.5L122 72.5ZM94 72.5L122 72.5L122 69.5L94 69.5L94 72.5Z"
        fill="#D819FF"
      />
      <path
        d="M94 77.5L92.5 77.5L92.5 80.5L94 80.5L94 77.5ZM122 80.5C122.828 80.5 123.5 79.8284 123.5 79C123.5 78.1716 122.828 77.5 122 77.5L122 80.5ZM94 80.5L122 80.5L122 77.5L94 77.5L94 80.5Z"
        fill="#D819FF"
      />
      <path
        d="M32 87L89 87"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="square"
      />
      <path
        d="M94 85H95"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M111 63H112"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M100 85H103"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M107 85H109"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M114 85H116"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M121 85H122"
        stroke="#D819FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63 49H125C127.209 49 129 50.7909 129 53V95C129 97.2091 127.209 99 125 99H94V102H125C128.866 102 132 98.866 132 95V53C132 49.134 128.866 46 125 46H63C59.134 46 56 49.134 56 53V54H59V53C59 50.7909 60.7909 49 63 49Z"
        fill="#D819FF"
      />
    </svg>
  );
};

export default CardSVG;
