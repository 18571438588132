import React from "react";
import Button from "../../../Components/Button/Button";
import Logo from "../../../Assets/Images/logo.png";
import "./Info.css";
// import { AppContext } from "../../../Context/Context";
import { CARL_LINK } from "../../../Constant/onboardingData";

const Info = ({ handleToUpdate }: { handleToUpdate: () => void }) => {
  // const [selected, setselected] = React.useState<boolean | null>(null);
  // const { setusResident } = React.useContext(AppContext);
  const clickHandler = () => {
    // setusResident(selected!);
    // localStorage.setItem("usResident", JSON.stringify(selected));
    handleToUpdate();
  };
  return (
    <div className="container">
      <div className="header">
        <div className="mainHeader">
          <img src={Logo} alt="logo" />
          <h2 className="login-H2">Welcome to CARL!</h2>
        </div>
        <ul>
          <li>Welcome to CARL accredited investor club and investment platform where you will access exclusive opportunities and share with like-minded investors.</li>
          <li>Invest in the best alternative investment opportunities on the leading platform for alternative assets.</li>
        </ul>

        {/* <div className="btnContainer">
          <Button
            label="YES"
            className={selected ? "activeButtonText" : "buttonText"}
            icon={<i className="fas fa-check-circle Icon"></i>}
            onClick={() => setselected(true)}
          />
          <Button
            label="NO"
            className={
              selected === null || selected ? "buttonText" : "activeButtonText"
            }
            icon={<i className="fas fa-times-circle Icon"></i>}
            onClick={() => setselected(false)}
          />
        </div> */}
        <div className="continueBtn">
        <p className="verif_text">To verify you are an accredited investor you need to create a CARL account.</p>
          <Button
            label="Continue"
            // disabled={selected === null}
            className={"buttonContinue"}
            onClick={clickHandler}
          />
        </div>

        <div>
          <p className="footer">
            Trouble logging in ? Email us at
            <span className="footerSpan">
              <a
                className="termLink"
                href={`mailto:${CARL_LINK}`}
                target="_blank"
                rel="noreferrer"
              >
                {CARL_LINK}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
