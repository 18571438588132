import Button from "../../../Components/Button/Button";
import Logo from "../../../Assets/Images/card.png";
import "./style.css";
import { useHistory } from "react-router-dom";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import CardSVG from "./CardSVG";
import { useState, useEffect } from "react";
import { Info } from "../../../Api/onBoarding";
import { Routes } from "../../../Constant/Routes/Routes";

const Identification = ({ handleToUpdate }: { handleToUpdate: () => void }) => {
  const history = useHistory();

  const checkFlow = async () => {
    const { message = {} } = await Info.GetUserDetails();
    const {
      data: {
        personalinfodata = {},
        kycinfodata = {},
        addressinfodata = {},
        accredited_investor_data = {},
        business_entity_proof_data = {},
      },
    } = message;
    const hasReloaded = localStorage.getItem("hasReloaded") || "false";
    // Reload the page only if kycinfodata is empty and we haven't reloaded yet
    if (!Object.keys(kycinfodata).length && hasReloaded !== "true") {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload(); // Reload the page
    }
  };

  useEffect(() => {
    checkFlow();
  }, []);
  return (
    <div className="container">
      <div className="header">
        <div className="logoContainer">
          <CardSVG />
        </div>

        <div>
          <h4 className="title">Next, we need to verify your identity</h4>
        </div>
        <div
          className="announcementTitle"
          onClick={() => {
            history.push("/", { step: 1 })
            localStorage.removeItem('userId')
          }}
        >
          * I do not have a copy of my documents available. I will complete my
          application later.
        </div>
        {/* <PrivacyContainer /> */}
        <div className="continueBtn">
          <Button
            label="Continue"
            className="buttonContinue"
            onClick={handleToUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default Identification;
