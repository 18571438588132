export interface IOnboardingRoutes {
  PERSONAL_INFO_SCREEN: string;
  IDENTIFICATION: string;
  ADDRESS_VERIFICATION: string;
  FINALIZE_ONBOARDING: string;
  ACCREDITED_DOC:string;
  ENTITY_BUSINESS:string;
  HOME_SCREEN: string;
  REJECT_SCREEN: string;
  DASHBOARD: string;
  MAIN: string;
}

export const OnboardingRoutes: IOnboardingRoutes = {
  PERSONAL_INFO_SCREEN: "PersonalInfo",
  IDENTIFICATION: "Identification",
  ADDRESS_VERIFICATION: "AddressVerification",
  FINALIZE_ONBOARDING: "FinalizeOnboarding",
  ACCREDITED_DOC:"AccreditedDocs",
  ENTITY_BUSINESS:"BusinessVerification",
  HOME_SCREEN: "HomeScreen",
  REJECT_SCREEN: "RejectScreen",
  DASHBOARD: "dashboard",
  MAIN: "/",
};
