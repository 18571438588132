import Button from "../../../Components/Button/Button";
import idcardLogo from "../../../Assets/Images/idcard.png";

import "./style.css";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import { AppContext } from "../../../Context/Context";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import IdcardSvg from "./IdcardSvg";
import { Info } from "../../../Api/onBoarding";
import { Routes } from "../../../Constant/Routes/Routes";
const VerifiAddressScreen = ({
  handleToUpdate,
  setcurrentStep,
}: {
  handleToUpdate: () => void;
  setcurrentStep: (number: number) => void;
}) => {
  const investorType: any = localStorage.getItem("investorType");
  const history = useHistory();
  const checkFlow = async () => {
    const { message = {} } = await Info.GetUserDetails();
    const {
      data: {
        personalinfodata = {},
        kycinfodata = {},
        addressinfodata = {},
        accredited_investor_data = {},
        business_entity_proof_data = {},
      },
    } = message;
    const hasReloaded = localStorage.getItem("hasReloaded") || "false";
    // Reload the page only if kycinfodata is empty and we haven't reloaded yet
    if (
      ((!Object.keys(addressinfodata).length &&
      addressinfodata.docFile === null) || localStorage.getItem("img"))  &&
      hasReloaded !== "true"
    ) {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload(); // Reload the page
    }
  };
  useEffect(() => {
    checkFlow();
  }, []);

  return (
    <div className="container">
      <div className="header">
        <div className="logoContainer">
          <IdcardSvg />
        </div>

        <div className="titleContainerSuccess">
          <h4 className="title">
            {investorType === "1" || investorType === "2"
              ? "Next, we need to verify your entity address"
              : "Next, we need to verify your address"}
          </h4>
        </div>
        <div
          className="announcementTitle"
          onClick={() => {
            history.push("/", { step: 1 })
            localStorage.removeItem('userId')
          }}
        >
          * I do not have a copy of my documents available. I will complete my
          application later.
        </div>
        {/* <PrivacyContainer /> */}
        <Button
          label="Continue"
          className="buttonContinue"
          onClick={handleToUpdate}
        />
      </div>
    </div>
  );
};

export default VerifiAddressScreen;
