// import React from "react";
// import { ApplicationStatus } from "../../Constant/onboardingData";
// import { AppContext } from "../../Context/Context";
// import { useHistory } from "react-router-dom";
// import "./HomeScreen.css";
// import { Routes } from "../../Constant/Routes/Routes";
// import { GetUserStatus } from "../../Api/onBoarding";
// import { CARL_LINK } from "../../Constant/onboardingData";
// const HomeScreen = () => {
//   const { firstName, lastName, applicationStatus, setApplicationStatus, userId } =
//     React.useContext(AppContext);
//   const history = useHistory();
//   const   handler = () => {
//     history.replace(Routes.IDENTIFICATION);
//   };

//   const checkFlow = React.useCallback(async () => {
//     const data = await GetUserStatus.fetchMainData(userId);
//     setApplicationStatus(data.message.application_status)
//   }, [setApplicationStatus, userId]);

//   React.useEffect(() => {
//     checkFlow()
//     setTimeout(() => {
//       history.replace("/")
//     }, 5000);
//   }, [checkFlow])


//   const userStatus = () => {
//     if (applicationStatus) {
//       if (applicationStatus === ApplicationStatus.PENDING) {
//         return (<>
//         <h5 className="text-white">Your application is being reviewed</h5>
//         <div>
//           <p className="footer">
//             Any Questions ? Email us at
//             <span className="footerSpan">
//               <a
//                 className="termLink"
//                 href={`mailto:${CARL_LINK}`}
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 {CARL_LINK}
//               </a>
//             </span>
//           </p>
//         </div></>
//         )
//       } else if (applicationStatus === ApplicationStatus.APPROVED) {
//         return <h5 className="text-white">Your application has been Approved</h5>
//       }
//       return <>
//         <button className="setUpAccountBtn" onClick={handler}>
//           <span>Finish setting up your account</span>
//         </button>
//         <span className="text-white">
//           and start building portfolios
//         </span>
//       </>
//     }
//     return null

//   }

//   return (
//     <div className="container">
//       <div className="HomePageContainer">
//         <div className="carlLogoContainer">
//           <img
//             src="https://investwithcarl.com/typo3conf/ext/carl-template/Resources/Public/images/logo.svg"
//             alt="logo"
//             className="carl-logo"
//           />
//         </div>
//         <div className="homeScreenSuccess">
//           {/* {firstName ? (
//             <>
//               <h2 className="porfolioHeading">
//                 Welcome to CARL
//                 {applicationStatus ? ` , ${firstName} ${lastName}!` : null}
//               </h2>
              
//             </>
//           ) : (
//             null
//           )} */}

//           {userStatus()}

//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeScreen;

import * as React from "react";
import { useHistory } from "react-router";
import SuccesLogo from "../../Assets/Images/check.png";
import "../../Components/SuccessScreen/SuccessScreen";
import { GetUserStatus } from "../../Api/onBoarding";
import { AppContext } from "../../Context/Context";
interface SuccessScreenProps {
  title: string;
  heading: string;
  navigate?: string;
  button?: string;
  label?: string;
}

const HomeScreen = ({
  title,
  heading,
  navigate,
  button,
  label,
}: SuccessScreenProps) => {
  const history = useHistory();

  const { firstName, lastName, applicationStatus, setApplicationStatus, userId } =
  React.useContext(AppContext);

  const checkFlow = React.useCallback(async () => {
    const data = await GetUserStatus.fetchMainData(userId);
    setApplicationStatus(data.message.application_status)
  }, [setApplicationStatus, userId]);

  React.useEffect(() => {
      checkFlow()
      setTimeout(() => {
        history.replace("/")
      }, 5000);
  }, [checkFlow])

  const handler = () => {
    history.replace(`/${navigate}`);
  };

  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="logoContainer">
            <img src={SuccesLogo} alt="logo" />
          </div>
          <div className="successScreenContainer">
            <h2 className="succesTitle" style={{color:"white",fontSize:"32px"}}>Your application is complete</h2>
            <h5 className="title" style={{fontSize:"20px"}}>It usually takes no more than 48hrs to approve. We will notify you when your account is ready.</h5>
          </div>
          {/* {label === 'Done' && button ? null : (
            <div className="continueBtn">
              <Button
                label={label ? label : "Continue"}
                className="buttonContinue"
                onClick={handler}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;

