import * as React from "react";
import { useDropzone } from "react-dropzone";
import "./dropzone.css";

interface FileUploaderProps {
  heading?: string;
  onFileSelect: (file: File) => void;
  disabled: boolean;
}

const FileUpload = ({ heading, onFileSelect, disabled }: FileUploaderProps) => {
  const [errors, setErrors] = React.useState("");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 50000000,
    multiple: false, // Ensure only one file can be selected
  });

  React.useEffect(() => {
    if (acceptedFiles.length) {
      handleFileInput(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const handleFileInput = (e: React.FormEvent | any) => {
    if (acceptedFiles.length) {
      onFileSelect(e); // Pass the file to the parent component
    } else {
      const target = e.target as HTMLInputElement;
      const file = (target.files as FileList)[0];
      onFileSelect(file); // Handle file selection from manual input
    }
  };

  return (
    <div className="documentTitleContainer">
      {heading ? <h4 className="heading">{heading}</h4> : null}
      <div {...getRootProps({ className: "dropzone" })}>
        <input
          {...getInputProps()}
          onChange={handleFileInput}
          accept="image/png, image/gif, image/jpeg, application/pdf"
          disabled={disabled}
        />
        <div className="dropzoneContainer">
          <p className="dropzoneContainerContent">
            Drag & drop or <span className="span">browse</span>
          </p>
        </div>
        <p style={{ color: "red", padding: 5, margin: 0, fontSize: 14 }}>
          {errors}
        </p>
      </div>
    </div>
  );
};

export default FileUpload;
