import React, { useState, ChangeEvent, useEffect, FocusEvent } from "react";
import InputMask from "react-input-mask";
import InputField from "../Input/InputField/InputField";
import { AppContext } from "../../Context/Context";
import {
  NON_US_ENTITY_TITLE,
  NON_US_HEADING,
  FOOTER_MESSAGE,
} from "../../Constant/Content";
import "./NonUsFlow.css";
import { INVESTOR_TYPE } from "../../Constant/onboardingData";
import Button from "../../Components/Button/Button";
import { Info } from "../../Api/onBoarding";
import PrivacyContainer from "../PrivacyPolicy/PrivacyContainer";

const NonUsEntity = ({ handleToUpdate }: { handleToUpdate: () => void }) => {
  const { firstName, setFirstName, setFirstNameErr } =
    React.useContext(AppContext);
  const { lastName, setLastName, setLastNameErr } =
    React.useContext(AppContext);
  const { company, setCompany, setCompanyErr } = React.useContext(AppContext);
  const { setEinNumber } = React.useContext(AppContext);
  const { investorType, userId, einNumber, phone_Number, loginEmail } =
    React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const [SSN, setSSN] = React.useState("");
  const [firstNameValue, setFirstNameValue] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameValue, setLastNameValue] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [einError, setEinError] = useState<boolean>(true);
  const [ssnError, setSsnError] = useState<boolean>(true);
  const [companyError, setCompanyError] = useState<string>("");

  const handleFirstNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const firstName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/; // Allow alphabetic characters and spaces

    // First check for character validation
    if (!characterRegex.test(firstName)) {
      setFirstNameError(
        "Input must contain only alphabetic characters and spaces."
      );
      setFirstNameErr(
        "Input must contain only alphabetic characters and spaces."
      );
    }
    // Then check for length validation
    else if (firstName.length > 30) {
      setFirstNameError("Must be 30 characters or less.");
      setFirstNameErr("Must be 30 characters or less.");
    } else {
      setFirstNameValue(firstName);
      setFirstName(firstName);
      setFirstNameError(""); // No error
      setFirstNameErr(""); // No error
    }
  };

  const handleFirstNameBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
    if (firstNameValue === "") {
      setFirstNameError("First name is required.");
      setFirstNameErr("First name is required.");
    } else if (
      characterRegex.test(firstNameValue) &&
      firstNameValue.length <= 30
    ) {
      setFirstNameError("");
      setFirstNameErr("");
    }
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const lastName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/; // Allow alphabetic characters and spaces

    // First check for character validation
    if (!characterRegex.test(lastName)) {
      setLastNameError(
        "Input must contain only alphabetic characters and spaces."
      );
      setLastNameErr(
        "Input must contain only alphabetic characters and spaces."
      );
    }
    // Then check for length validation
    else if (lastName.length > 30) {
      setLastNameError("Must be 30 characters or less.");
      setLastNameErr("Must be 30 characters or less.");
    } else {
      setLastNameValue(lastName);
      setLastName(lastName);
      setLastNameError(""); // No error
      setLastNameErr(""); // No error
    }
  };

  const handleLastNameBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
    if (lastNameValue === "") {
      setLastNameError("Last name is required.");
      setLastNameErr("Last name is required.");
    } else if (
      characterRegex.test(lastNameValue) &&
      lastNameValue.length <= 30
    ) {
      setLastNameError("");
      setLastNameErr("");
    }
  };

  const handleEINChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const ein: string = event.target.value;
    setEinNumber(ein);
    setEinError(ein.length !== 9); // EIN must be 9 characters
  };

  const handleSSNChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const ssn = event.target.value;
    setSSN(ssn);
    setSsnError(ssn.replace(/[^0-9]/g, "").length !== 9); // SSN must be 9 digits
  };
  // Validation for Company Name (Cannot be just numbers)
  const handleCompanyChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const companyName: string = event.target.value;
    const alphabetRegex: RegExp = /[a-zA-Z]/;

    if (!alphabetRegex.test(companyName) && companyName !== "") {
      setCompanyError(
        "Company name must contain at least one alphabetic character."
      );
      setCompanyErr(
        "Company name must contain at least one alphabetic character."
      );
    } // Then check for length validation
    else if (companyName.length > 200) {
      setCompanyError("Must be 200 characters or less.");
      setCompanyErr("Must be 200 characters or less.");
    } else {
      setCompany(companyName);
      setCompanyError(""); // No error if it contains alphabetic characters
      setCompanyErr(""); // No error if it contains alphabetic characters
    }
  };

  // Handle when last name field loses focus (onBlur)
  const handleCompanyBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const companyName: string = event.target.value;
    const alphabetRegex: RegExp = /[a-zA-Z]/;
    if (companyName === "") {
      setCompanyError("Company name is required.");
      setCompanyErr("Company name is required.");
    } else if (alphabetRegex.test(companyName) && companyName.length <= 200) {
      setCompanyError("");
      setCompanyErr("");
    }
  };

  const isFormValid =
    firstNameValue &&
    firstNameError === "" &&
    lastNameValue &&
    lastNameError === "" &&
    company &&
    companyError === "" && // Company name validation passes
    !ssnError && // SSN validation passes
    !einError && // EIN validation passes
    SSN.replace(/[^0-9]/g, "").length === 9 &&
    einNumber.length === 9;

  let localStoredEmail: any = localStorage.getItem("email");
  const email = loginEmail ? loginEmail : JSON.parse(localStoredEmail);
  let localStoredPhoneNumber: any = localStorage.getItem("phoneNumber");
  const phone = phone_Number
    ? phone_Number
    : JSON.parse(localStoredPhoneNumber);
  const ssn = SSN.replace(/[^0-9]/g, "");

  const handleSubmit = async () => {
    setLoading(true);
    const response = await Info.submitEntityForm({
      company,
      email,
      firstName,
      lastName,
      userId,
      phone_Number: phone,
      einNumber,
      ssn,
    });
    if (response.status && investorType === "1") {
      if (response.message.success) {
        handleToUpdate();
      }
    }
  };

  useEffect(() => {
    setCompany("");
  }, []);

  return (
    <div className={`${investorType === "1" && "container"}`}>
      <div className={`${investorType === "1" && "header"}`}>
        <div>
          {investorType === "1" && (
            <h4 className="nonUsTitle">{NON_US_ENTITY_TITLE}</h4>
          )}
          <h3 className="title">{NON_US_HEADING}</h3>
          <InputField
            type="text"
            placeholder="First Name"
            name="firstName"
            value={firstNameValue}
            onChange={handleFirstNameChange}
            onBlur={handleFirstNameBlur}
            className="inputField"
          />
          {firstNameError !== "" && (
            <p style={{ color: "red" }}>{firstNameError}</p>
          )}

          <InputField
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={lastNameValue}
            onChange={handleLastNameChange}
            onBlur={handleLastNameBlur}
            className="inputField"
          />
          {lastNameError !== "" && (
            <p style={{ color: "red" }}>{lastNameError}</p>
          )}

          {investorType === INVESTOR_TYPE.Entity && (
            <>
              <InputMask
                mask="999-99-9999"
                className="inputField"
                maskChar={null}
                onChange={handleSSNChange}
                placeholder="Social Security Number"
              />
              {SSN.length > 0 && ssnError && (
                <p style={{ color: "red" }}>SSN must be 9 digits.</p>
              )}
            </>
          )}

          <InputField
            type="text"
            placeholder="Company Name"
            name="company"
            value={company}
            onChange={handleCompanyChange}
            onBlur={handleCompanyBlur}
            className="inputField"
          />
          {companyError !== "" && (
            <p style={{ color: "red" }}>{companyError}</p>
          )}

          {investorType === INVESTOR_TYPE.Entity && (
            <>
              <InputMask
                mask="999999999"
                className="inputField"
                maskChar={null}
                onChange={handleEINChange}
                placeholder="Business EIN"
              />
              {einNumber.length > 0 && einError && (
                <p style={{ color: "red" }}>EIN must be 9 digits.</p>
              )}
            </>
          )}

          <div>
            <p style={{ marginTop: 10 }}>{FOOTER_MESSAGE}</p>
          </div>
        </div>
        {investorType === INVESTOR_TYPE.Entity && <PrivacyContainer />}
        {investorType === "1" && (
          <div className="continueBtn">
            <Button
              label="Continue"
              className={isFormValid ? "buttonContinue" : "deactiveButton"}
              icon={
                loading ? (
                  <i className="fas fa-circle-notch fa-spin mt-2"></i>
                ) : null
              }
              disabled={!isFormValid || loading}
              onClick={handleSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NonUsEntity;
