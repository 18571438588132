import React from 'react';
import Sidebar from './Sidebar';

const Dashboard = () => {
  return (
    <div style={{background:"white", width:"100vw", height:"100vh"}} className='d-flex'>
      <Sidebar/>
    </div>
  );
}

export default Dashboard;
