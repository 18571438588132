import React from "react";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import Button from "../../../Components/Button/Button";
import Accredited from "../../../Assets/Images/Accredited.svg";
import { useHistory } from "react-router-dom";
import AccreditedSVG from "./AccreditedSVG";
import { Info } from "../../../Api/onBoarding";
function IntroScreen({ handleToUpdate }: { handleToUpdate: () => void }) {
  const history = useHistory();
  const checkFlow = async () => {
    const { message = {} } = await Info.GetUserDetails();
    const {
      data: {
        personalinfodata = {},
        kycinfodata = {},
        addressinfodata = {},
        accredited_investor_data = {},
        business_entity_proof_data = {},
      },
    } = message;
    const hasReloaded = localStorage.getItem("hasReloaded") || "false";
    // Reload the page only if kycinfodata is empty and we haven't reloaded yet
    if (!Object.keys(accredited_investor_data).length && hasReloaded !== "true") {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload(); // Reload the page
    }
  };
  React.useEffect(() => {
    checkFlow();
  }, []);
  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="logoContainer">
            <AccreditedSVG />
          </div>
          <div className="titleContainerSuccess">
            <h4 className="title">
              Next, we need to verify your Accredited Investor Status
            </h4>
          </div>
          <div
            className="announcementTitle"
            onClick={() => {
              history.push("/", { step: 1 })
              localStorage.removeItem('userId')
            }}
          >
            * I do not have a copy of my documents available. I will complete my
            application later.
          </div>
          {/* <PrivacyContainer /> */}
          <Button
            label="Continue"
            className="buttonContinue"
            onClick={handleToUpdate}
          />
        </div>
      </div>
    </div>
  );
}

export default IntroScreen;
