import loadingIcon from "../../Assets/Images/loadingNew.gif";
import "./LoadingScreen.css";

const LoadingScreen = () => {
  return (
    <div className="container">
      <div className="loadingContainer">
        <img className="loadingImage" src={loadingIcon} alt="Icon" />
        <h2 className="loadingTitle">Loading...</h2>
      </div>
    </div>
  );
};

export default LoadingScreen;
