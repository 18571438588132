import React from "react";
import "./Modal.css"; // You can style your modal here
import { IoMdClose } from "react-icons/io";

const Modal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;
console.log("content",content)
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="certificate-modal-contain" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <IoMdClose />
        </button>
        {content !== undefined ? (
          <div className="certificate-container" style={{padding:"10px"}}>
            <h2 className="modal-title" style={{paddingBottom:"10px"}}>Investor Certificate</h2>
            <iframe
              src={content + '#toolbar=0'} // Hides the toolbar with download option in some browsers
              title="Certificate"
              className="certificate-frame"
              style={{ border: 'none' }}
            />
          </div>
        ) : (
          <h5 className="model-info">Certificate not released yet.</h5>
        )}
      </div>
    </div>
  );
};

export default Modal;
