// export const NON_US_ENTITY_TITLE = "Thank you for your interest in CARL";
export const NON_US_ENTITY_TITLE = "Accredited Investor Verification";
export const NON_US_HEADING =
  "We need more information about you and your company.";
export const NON_US_INDIVIDUAL_TITLE = "We need more information about you.";
export const NON_US_INDIVIDUAL_HEADING = `We are working hard to make CARL accessible to everyone, everywhere.
Based on your phone number we need to make sure CARL is available to
you.`;
export const FOOTER_MESSAGE =
  " Under federal securities law, CARL is limited to accredited investors only.";

export const ENTITY_SPLASH_TEXT = `First, we will ask a few questions about you and your business. You will need the following documents to complete your application:`;

export const NON_US_SPLASH_TEXT = `First, we will ask a few questions about you. You will need the following documents to complete your application:`;
