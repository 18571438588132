
import * as React from "react";
import { isMobile } from "react-device-detect";

import FileUpload from "../Input/FileUploadInput/FileUploadInput";
import Camera from "./OpenCamera";
import ShowCapturedImages from "./ShowCapturedImage";
import succes from "../../Assets/Images/right_icon-removebg-preview.png"

interface PassportCaptureProps {
    frontImage?: File | null;
    setFrontImage: (file: File | null) => void;
    retakeImage: () => void;
    disabled: boolean;
  }
  
  const PassportCapture: React.FC<PassportCaptureProps> = ({
    frontImage,
    setFrontImage,
    retakeImage,
    disabled
  }) => {
    const [frontImageURL, setFrontImageURL] = React.useState("");


    const retakeImageHandler = () =>{
        setFrontImageURL("");
        retakeImage();
      }
  
    if (isMobile)
      return !frontImage ? (
        <Camera
          setFrontImage={setFrontImage}
          setFrontImageURL={setFrontImageURL}
        />
      ) : (
        <ShowCapturedImages
          frontImageURL={frontImageURL!}
          retakeImage={retakeImageHandler}
        />
      );
    return (
      <>
        <FileUpload onFileSelect={(file) => setFrontImage(file)} disabled={disabled}/>
        <h6 className="imageName">{frontImage?.name}{frontImage?.name && <img src={succes} alt="" height="30"/>}</h6>
      </>
    );
  };

  export default PassportCapture
  