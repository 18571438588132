import React, { useState, useEffect } from "react";
// import SuccesLogo from "../../../Assets/Images/check.png";
import Button from "../../../Components/Button/Button";
// import { Document, Page } from 'react-pdf';

function SuccessAddress({ handleToUpdate }: any) {
  const [docImg, setDocImg] = useState<any>(null);
  const [docType, setDocType] = useState<string>('');
  const [counter, setCounter] = useState<number>(0);
  const [noImg, setNoImg] = useState(true);

  useEffect(() => {
    const img = localStorage.getItem("img");
    if (img) {
      setDocImg(img);
      const fileType = img.split('.').pop()?.toLowerCase();
      if (fileType === 'pdf') {
        setDocType('pdf');
      } else if (['png', 'jpg', 'jpeg', 'gif'].includes(fileType!)) {
        setDocType('image');
      }
    }
    const timeout = setTimeout(() => {
      setCounter(1);
      setNoImg(false);
      console.log(docImg, "last");
    }, 1000);
    return () => clearTimeout(timeout);
  }, [docImg]);

  const handlePreviewBtn = () => {
    handleToUpdate();
    localStorage.removeItem("img");
  };

  return (
    <div>
      <div className="container">
        <div className="header">
          <h2 style={{ color: "white" }}>Preview </h2>
          <div className="docPreview w-100">
            {noImg ? (
              <div className="d-flex justify-content-center w-100 ">
                <div>
                  <i className="fs-1 fas fa-circle-notch fa-spin mt-2"></i>
                  <p className="fs-5"> Please wait while we process your documents</p>
                </div>
              </div>
            ) : (
              <>
                {docType === 'image' && <img src={docImg} alt="doc" />}
                {docType === 'pdf' && (
                  <iframe
                    src={docImg}
                    width="100%"
                    height="500px"
                    title="PDF Preview"
                  />
                )}
              </>
            )}
          </div>
          <div className="continueBtn">
            <Button
              label="Try Again"
              className="buttonContinue"
              onClick={() => handleToUpdate("back")}
            />
            <Button
              label="Continue"
              className="buttonContinue"
              onClick={handlePreviewBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessAddress;
