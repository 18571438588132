import React, { useContext } from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import IntroScreen from "../../Onboarding/AccreditedInvestor/introScreen";
import AccreditedDoc from "../../Onboarding/AccreditedInvestor/index";
import MessageScreen from "../../../Components/SuccessScreen/SuccessScreen";
import {Routes} from "../../../Constant/Routes/Routes"
import { INVESTOR_TYPE } from "../../../Constant/onboardingData";
import { AppContext } from "../../../Context/Context";
const arraySteps = [
    { title: "Accredited Status" },
    { title: "Upload Documents" },
    { title: "Success" },
  ];

function AccreditedInvestor() {
  const investorType: any = localStorage.getItem("investorType");
  const [currentStep, setcurrentStep] = React.useState(0);
  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <IntroScreen handleToUpdate={()=>setcurrentStep(currentStep +1)} />;
      case 1:
        return <AccreditedDoc handleToUpdate={()=>setcurrentStep(currentStep +1)} />;
      case 2:
        return <MessageScreen title="Success" heading="We have received your Accredited Investor documents"  navigate={(investorType === INVESTOR_TYPE["Non US Entity"] ||investorType ===INVESTOR_TYPE.Entity)?Routes.ENTITY_BUSINESS:Routes.FINALIZE_ONBOARDING} />;
      default:
        return null;
    }
  };
  return (
    <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
      {renderComponent()}
    </MainStepper>
  );
}

export default AccreditedInvestor;